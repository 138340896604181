import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-5 mb-xl-8 card" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "align-items-end flex-column" }
const _hoisted_6 = {
  key: 0,
  class: "row"
}
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "card card-xl-stretch mb-xl-8" }
const _hoisted_9 = { class: "card-header border-0 py-5" }
const _hoisted_10 = { class: "card-title align-items-start flex-column" }
const _hoisted_11 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_12 = { class: "text-muted fw-bold fs-7" }
const _hoisted_13 = { class: "card-body d-flex flex-column" }
const _hoisted_14 = { class: "flex-grow-1" }
const _hoisted_15 = { class: "pt-5" }
const _hoisted_16 = { class: "text-center fs-6 pb-5" }
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = { class: "card card-xl-stretch mb-xl-8" }
const _hoisted_19 = { class: "card-header border-0 py-5" }
const _hoisted_20 = { class: "card-title align-items-start flex-column" }
const _hoisted_21 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_22 = { class: "text-muted fw-bold fs-7" }
const _hoisted_23 = { class: "card-body d-flex flex-column" }
const _hoisted_24 = { class: "flex-grow-1" }
const _hoisted_25 = { class: "pt-5" }
const _hoisted_26 = { class: "text-center fs-6 pb-5" }
const _hoisted_27 = { class: "col-6" }
const _hoisted_28 = { class: "card card-xl-stretch mb-xl-8" }
const _hoisted_29 = { class: "card-header border-0 py-5" }
const _hoisted_30 = { class: "card-title align-items-start flex-column" }
const _hoisted_31 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_32 = { class: "text-muted fw-bold fs-7" }
const _hoisted_33 = { class: "card-body d-flex flex-column" }
const _hoisted_34 = { class: "flex-grow-1" }
const _hoisted_35 = { class: "pt-5" }
const _hoisted_36 = { class: "text-center fs-6 pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("pages.roadmapstatistics.title")), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", {
            class: "btn btn-success btn-light",
            "data-bs-target": "#kt_modal_1",
            "data-bs-toggle": "modal",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.downloadUsers()), ["prevent"]))
          }, _toDisplayString(_ctx.$t("pages.roadmapstatistics.downloadRoadmaps")), 1)
        ])
      ])
    ]),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("h3", _hoisted_10, [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("pages.roadmapstatistics.creationRate")), 1),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("pages.roadmapstatistics.creationDesc")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_apexchart, {
                    options: _ctx.chartOptions,
                    series: _ctx.series,
                    class: "mixed-widget-4-chart",
                    height: "250",
                    type: "radialBar"
                  }, null, 8, ["options", "series"])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("p", _hoisted_16, [
                    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "badge badge-light-danger fs-8" }, "Notes:", -1)),
                    _createTextVNode("  " + _toDisplayString(_ctx.$t("pages.userstatistics.connectionRateDisclaimer")) + " ", 1),
                    _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(_toDisplayString(this.totalUsers) + " " + _toDisplayString(_ctx.$t("pages.userstatistics.connectionRateDisclaimerSecond")), 1)
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("h3", _hoisted_20, [
                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("pages.roadmapstatistics.categoryDistribution")), 1),
                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t("pages.roadmapstatistics.categoryDistributionDesc")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createVNode(_component_apexchart, {
                    options: _ctx.chartPieOptions,
                    series: _ctx.seriesPie,
                    class: "mixed-widget-4-chart",
                    height: "225",
                    type: "donut"
                  }, null, 8, ["options", "series"])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("p", _hoisted_26, [
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "badge badge-light-danger fs-8" }, "Notes:", -1)),
                    _createTextVNode("  " + _toDisplayString(_ctx.$t("pages.userstatistics.connectionRateDisclaimer")) + " ", 1),
                    _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(_toDisplayString(this.totalUsers) + " " + _toDisplayString(_ctx.$t("pages.userstatistics.connectionRateDisclaimerSecond")), 1)
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("h3", _hoisted_30, [
                  _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t("pages.roadmapstatistics.roadmapStatus")), 1),
                  _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t("pages.roadmapstatistics.roadmapStatusDesc")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _createVNode(_component_apexchart, {
                    options: _ctx.chartBarOptions,
                    series: _ctx.seriesBar,
                    class: "mixed-widget-4-chart",
                    height: "225",
                    type: "bar"
                  }, null, 8, ["options", "series"])
                ]),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("p", _hoisted_36, [
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "badge badge-light-danger fs-8" }, "Notes:", -1)),
                    _createTextVNode("  " + _toDisplayString(_ctx.$t("pages.userstatistics.connectionRateDisclaimer")) + " ", 1),
                    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(_toDisplayString(this.totalUsers) + " " + _toDisplayString(_ctx.$t("pages.userstatistics.connectionRateDisclaimerSecond")), 1)
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}